import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {SplitText} from "gsap/dist/SplitText";
import './form-submissions';
import './static.addtoany.com_menu_page.js';

import {Smoke, Smoke2} from "./smoke";

gsap.registerPlugin(ScrollTrigger, SplitText);

// gsap.to("h1", {x: 200});

// Only want to do this if we are on the home page and the .intro-visible isn't
// on the interior pages

// Wait for the document content to be loaded so we can access the DOM elements with valid dimensions.
document.addEventListener('DOMContentLoaded', (event) => {
	if (document.querySelector('.intro-visible')) {
		const smoke = new Smoke();

		smoke.update();
		const smoke2 = new Smoke2();
		smoke2.update();
	}
});

/**
 * File navigation.js.
 *
 * Handles showing / hiding main nav on scroll
 * and toggling the navigation menu for small screens.
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */
(function($) {

	// ----------------------------------------------------------
	// Doc ready
	// ----------------------------------------------------------
	$(document).ready(
		() => {
      const a2a_config = {};
      a2a_config.num_services = 0;

			// ----------------------------------------------------------
			// Main Navigation open / close submenus
			// ----------------------------------------------------------
			if ($('.main-menu').length) {
				const mainNavMediaQuery = window.matchMedia('(max-width: 1199px)')

				function handleViewportChange(e)
				{
					// Check if the media query is true
					if (e.matches) {
						$(document).on(
							'click tap keyup',
							'.main-menu .menu-item-has-children > a',
							function(e) {
								let myLi = $(this).parent('.menu-item-has-children');
								// let mySub = $(this).next('.sub-menu');

								// if (myLi.hasClass('submenu-open') || myLi.hasClass('current-menu-item') || myLi.hasClass('current-menu-ancestor')) {
								if (myLi.hasClass('submenu-open')) {
									return true;
								} else {
									e.preventDefault();
									$(myLi).siblings('.menu-item-has-children').removeClass('submenu-open');
									$(myLi).addClass('submenu-open');
								}
							}
						)
						// hide sub-menu on ESC.
						$(document).on(
							'keyup',
							function(e) {
								if (e.keyCode === 13) {
									return true;
								}
								if (e.which === 27) {
									$('.menu-item-has-children').removeClass('submenu-open');
								}
							}
						)
						// hide opened menu by clicking outside it.
						$(document).on(
							'click tap keyup',
							function(e) {
								if (!$(e.target).closest('.main-menu').length) {
									$('.menu-item-has-children').removeClass('submenu-open');
								}
							}
						)
					} else {
						$(document).off(
							'click tap keyup',
							'.main-menu .menu-item-has-children > a'
						)
						let menu_lis = document.querySelectorAll('.submenu-open');
						menu_lis.forEach(menu_li => {
							let menu_li_link = menu_li.querySelector('a');
							let menu_li_btn = menu_li.querySelector('button');
							menu_li_btn.blur();
							menu_li_link.blur();
							menu_li.classList.remove('submenu-open');
						});
					}
				}

				$(document).on(
					'click tap keyup',
					'.submenu-toggle',
					function(e) {
						let myLi = $(this).parent('.menu-item-has-children');
						if (myLi.hasClass('submenu-open')) {
							$(myLi).removeClass('submenu-open');
						} else {
							e.preventDefault();
							$(myLi).siblings('.menu-item-has-children').removeClass('submenu-open');
							$(myLi).addClass('submenu-open');
						}
					}
				);

				// Register event listener
				mainNavMediaQuery.addEventListener('change', handleViewportChange);

				// Initial check
				handleViewportChange(mainNavMediaQuery);
			} // end if main menu exists.

			// ----------------------------------------------------------
			// Language Navigation open / close submenus
			// ----------------------------------------------------------
			if ($('.language-menu').length) {

				$(document).on(
					'click tap keyup',
					'.language-menu li > a',
					function(e) {
						let myLi = $(this).parent();
						// let mySub = $(this).next('.sub-menu');

						// if (myLi.hasClass('submenu-open') || myLi.hasClass('current-menu-item') || myLi.hasClass('current-menu-ancestor')) {
						if (myLi.hasClass('submenu-open')) {
							$(myLi).removeClass('submenu-open');
							return true;
						} else {
							e.preventDefault();
							$(myLi).siblings().removeClass('submenu-open');
							$(myLi).addClass('submenu-open');
						}
					}
				)
				// hide sub-menu on ESC.
				$(document).on(
					'keyup',
					function(e) {
						if (e.keyCode === 13) {
							return true;
						}
						if (e.which === 27) {
							$('.language-menu li').removeClass('submenu-open');
						}
					}
				)
				// hide opened menu by clicking outside it.
				$(document).on(
					'click tap keyup',
					function(e) {
						if (!$(e.target).closest('.language-menu').length) {
							$('.language-menu li').removeClass('submenu-open');
						}
					}
				)

				$(document).on(
					'click tap keyup',
					'.submenu-toggle',
					function(e) {
						let myLi = $(this).parent('li');
						if (myLi.hasClass('submenu-open')) {
							$(myLi).removeClass('submenu-open');
						} else {
							e.preventDefault();
							$(myLi).siblings('li').removeClass('submenu-open');
							$(myLi).addClass('submenu-open');
						}
					}
				);

			} // end if language menu exists.

		}
	); // end doc.ready.

	// Homepage Story Slider - depends on Slick.js - http://kenwheeler.github.io/slick/
	storySlick();

	function storySlick()
	{
		let hash = window.location.hash;

		// cache some selectors
		const activeClass = 'active';
		const $slideContainer = $('.home-story-slider');
		const $nav = $('<nav id="slider-nav"><ul></ul></nav>');
		let $slides = $('.story-slide');

		// append the navigation to the page
		$slideContainer.after($nav);

		window.onhashchange = function(event) {
			$('#slider-nav').find('a').removeClass(activeClass);
			setActiveAppearance();
		}

		function setActiveAppearance()
		{
			// check the hash value in the URL. If there's a match
			// in our navigation, highlight the correct link.
			hash = window.location.hash;
			let selector = $('#slider-nav').find('a:first');
			if (hash) {
				selector = $('[href="' + hash + '"]')
			}
			selector.addClass(activeClass);
		}

		function getActiveSlide()
		{
			let slides = $slideContainer.children();
			let activeSlideIndex = 0;
			// compare the window's hash value with our slides. If there's a match,
			// return the active slide index to slick can use that as the first slide on init
			slides.each(function(idx, slide) {
				if (slide.dataset.hash === hash.substring(1)) {
					activeSlideIndex = idx
				}
			});

			setActiveAppearance();

			return activeSlideIndex;
		}

		let $status = $('.slider-status');
		$slideContainer.on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
			//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
			let i = (currentSlide ? currentSlide : 0) + 1;
			$status.text(i + '/' + slick.slideCount);
			getActiveSlide();
		});

		// call the slick method!
		let $slider = $slideContainer.slick({
			appendDots: $('.home-story-footer .slider-nav'),
			arrows: true,
			dots: true,
			dotsClass: 'custom_paging',
			customPaging: function(slider, i) {
				//FYI just have a look at the object to find available information
				//press f12 to access the console in most browsers
				//you could also debug or look in the source
				console.log(slider);
				return (i + 1) + '/' + slider.slideCount;
			},
			draggable: false,
			fade: true,
			infinite: true,
			lazyLoad: 'progressive',
			nextArrow: $('.story-slick-next'),
			prevArrow: $('.story-slick-prev'),
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 2500,
			touchThreshold: 10,
			initialSlide: getActiveSlide(),
			waitForAnimate: false,
		});

		$slides.each(function(i, e) {
			let $element = $(e);
			let $anchor = $('<a />');
			$anchor.attr('href', '#' + e.dataset.hash);
			$anchor.text(e.dataset.title);
			$anchor.on('click', function(e) {
				$slider.slick('slickGoTo', i);
			});
			let $listitem = $('<li />');
			$listitem.append($anchor);
			$nav.find('ul').append($listitem);
		});

		setActiveAppearance();
	}

	// Image Gallery Sliders - depends on Slick.js - http://kenwheeler.github.io/slick/
	gallerySlick();

	function gallerySlick()
	{
		// cache some selectors
		const $gallery_slider = $('.gallery-slider');

		// call the slick method!

		$gallery_slider.slick({
			arrows: true,
			centerMode: true,
			dots: false,
			draggable: true,
			fade: false,
			infinite: true,
			// lazyLoad: 'progressive',
			nextArrow: $('.gallery-slick-next'),
			prevArrow: $('.gallery-slick-prev'),
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 500,
			touchThreshold: 10,
		});
	}




	// ----------------------------------------------------------
	// Window resize functions
	// ----------------------------------------------------------
	let resizeTimer;
	$(window).on(
		'resize',
		() => {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(
				() => {
				},
				250,
			);
		},
	); // end resize.
})(window.jQuery);

(function(window, document, undefined) {

	// GSAP 3, ScrollTrigger site-header show/hide - depends on 'gsap-js' and 'scrolltrigger-js' in ppsaa_scripts()
	const siteHeader = document.querySelector('.site-header');
	const siteHeaderHeight = siteHeader.offsetHeight;
	const docBody = document.body;

	// add .header-top class to <body> when nearing top of page
	ScrollTrigger.create({
		start: `+=${(-1 * siteHeaderHeight)}`,
		// end: () => `+=${document.querySelector('.site-header').offsetHeight}`,
		end: `+=${(1.34 * siteHeaderHeight)}`,
		// markers:true,
		scrub: true,
		trigger: docBody,
		toggleClass: {className: 'header-top', targets: docBody},
		onUpdate: () => {
			docBody.classList.remove('header-short')
		}
	});

	let param, checkParam, triggered;

	// show and hide fixed header based on scroll direction
	// adds buffer when scrolling up before header appears
	// inspiration for buffer: https://greensock.com/forums/topic/29468-scrolltrigger-on-fixed-header/#comment-147082
	ScrollTrigger.create({
		start: "top" + `+=${(siteHeaderHeight * .34)}`,
		end: "max",
		scrub: true,
		trigger: docBody,
		// toggleClass: {className: 'header-hide', targets: docBody},
		onUpdate: (self) => {
			// console.log( self.scroll() )
			// console.log( self.progress )
			// console.log( self.direction )
			if (self.direction === 1 && self.scroll() !== 0) {
				triggered = false
				param = self.scroll()
				docBody.classList.add('header-hide')
				docBody.classList.remove('header-short')
			}

			if (self.direction === -1 && !triggered) {
				checkParam = self.scroll()
				//console.log(param - checkParam)
				//if( param - checkParam >= 1000 || self.scroll() === 0 ) { // <--- Distance to be covered
				if (param - checkParam >= 40 || self.progress === 0) { // <--- Distance to be covered
					docBody.classList.remove('header-hide')
					docBody.classList.add('header-short')
					triggered = true
				}
			}
		}
	});

	// MOBILE SLIDER MENU
	// http://coding.smashingmagazine.com/2013/01/15/off-canvas-navigation-for-responsive-website/
	// helper functions.
	const trim = function(str) {
		return str.trim ? str.trim() : str.replace(/^\s+|\s+$/g, '');
	};
	const hasClass = function(el, cn) {
		return (' ' + el.className + ' ').indexOf(' ' + cn + ' ') !== -1;
	};
	const addClass = function(el, cn) {
		if (!hasClass(el, cn)) {
			el.className = (el.className === '') ? cn : el.className + ' ' + cn;
		}
	};
	const removeClass = function(el, cn) {
		if (el) {
			el.className = trim((' ' + el.className + ' ').replace(' ' + cn + ' ', ' '));
		}
	};
	const setAttributes = function(el, attrs) {
		if (el) {
			for (let key in attrs) {
				el.setAttribute(key, attrs[key]);
			}
		}
	};
	const hasParent = function(el, id) {
		if (el) {
			do {
				if (el.id === id) {
					return true;
				}
				if (el.nodeType === 9) {
					break;
				}
			} while ((el = el.parentNode));
		}
		return false;
	};

	// normalize vendor prefixes.
	const doc = document.documentElement;

	// off-canvas slider actions.
	window.App = (function() {
		let _init = false;
		const app = {};
		const site_nav = document.getElementById('site-nav'),
			header_toggle_btn = document.getElementById('header-menu-toggle'),
			btn_class = 'is-active',
			nav_class = 'js-nav';
		let nav_open = false;

		app.init = function() {
			if (_init) {
				return;
			}
			_init = true;

			app.closeNav = function() {
				if (nav_open) {
					setAttributes(header_toggle_btn, {'title': 'Main Menu', 'aria-expanded': 'false'});
					setAttributes(site_nav, {'aria-expanded': 'false'});
				}
				removeClass(doc, nav_class);
				removeClass(header_toggle_btn, btn_class);
				nav_open = false;
			};
			app.openNav = function() {
				if (nav_open) {
					return;
				}
				addClass(doc, nav_class);
				addClass(header_toggle_btn, btn_class);
				setAttributes(header_toggle_btn, {'title': 'Close Menu', 'aria-expanded': 'true'});
				setAttributes(site_nav, {'aria-expanded': 'true'});
				nav_open = true;
			};
			app.toggleNav = function(e) {
				if (nav_open && hasClass(doc, nav_class)) {
					app.closeNav();
				} else {
					app.openNav();
				}
				if (e) {
					e.stopPropagation();
					e.preventDefault();
				}
			};
			// open nav with main "nav" button.
			if (header_toggle_btn !== undefined) {
				header_toggle_btn.addEventListener('click', app.toggleNav, false);
			}
			// close nav by touching the partial off-screen content.
			document.addEventListener(
				'click',
				function(e) {
					if (nav_open && !hasParent(e.target, 'nav-block')) {
						e.stopPropagation();
						e.preventDefault();
						app.closeNav();
					}
				},
				true
			);
			addClass(doc, 'js-ready');
		};
		return app;
	})();
	if (window.addEventListener) {
		window.addEventListener('DOMContentLoaded', window.App.init, false);
	}
	// end MOBILE SLIDER MENU.

})(window, window.document);

/**
 * JavaScript functions for this block
 */

(function(window, document, undefined) {

	let hero_block =

		// Background video play/pause button actions
		bgVideoControl();

	function bgVideoControl()
	{
		let bg_vids = document.querySelectorAll('.video-bg');
		for (let i = 0; i < bg_vids.length; i++) {
			let vid = bg_vids[i];
			let vid_parent = vid.parentNode.parentNode.parentNode;
			let pause_button = vid_parent.querySelector('.video-play-pause');

			function vidFade(vid)
			{
				vid.classList.add('stopfade');
			}

			function vidPlay(vid, pause_button)
			{
				vid.play();
				pause_button.innerHTML = 'Pause video';
				pause_button.classList.add('active');
				pause_button.setAttribute('title', 'Pause video');
				pause_button.setAttribute('aria-pressed', 'true');
			}

			function vidPause(vid, pause_button)
			{
				vid.pause();
				pause_button.innerHTML = 'Play video';
				pause_button.classList.remove('active');
				pause_button.setAttribute('title', 'Play video');
				pause_button.setAttribute('aria-pressed', 'false');
			}

			if (vid && pause_button) {
				vid.addEventListener('ended', function() {
					// only functional if "loop" is removed
					vidPause(vid, pause_button);
					// to capture IE10
					vidFade(vid);
				});

				if (window.matchMedia('(prefers-reduced-motion)').matches) {
					vid.removeAttribute('autoplay');
					vidPause(vid, pause_button);
				} else {
					vidPlay(vid, pause_button);
				}

				pause_button.addEventListener('click', function() {
					vid.classList.toggle('stopfade');
					if (vid.paused) {
						vidPlay(vid, pause_button);
					} else {
						vidPause(vid, pause_button);
					}
				});
				// vidPause(vid, pause_button);
			}
		}
	}

	// Window load functions
	window.addEventListener(
		'load',
		function load() {
			window.removeEventListener('load', load, false);
			document.body.classList.add('loaded');
		},
		false
	);

	const docBody = document.body;

	// set CSS variable for browser's scrollbar width and adjust on browser resize
	// enables 'alignfull' content to span the full width of viewport without triggering overscroll-x scrollbar
	setScrollbarWidth();

	function setScrollbarWidth()
	{
		const body = document.querySelector('body');
		const scrollbar = window.innerWidth - body.clientWidth;
		body.setAttribute('style', `--scrollbar: ${scrollbar}px`);
	}

	window.addEventListener('resize', setScrollbarWidth);

	// GSAP 3, ScrollTrigger parallax - depends on 'gsap-js' and 'scrolltrigger-js' in homebase_scripts()
	gsap.config({
		force3D: true,
		nullTargetWarn: false,
	});

	// GSAP 3, ScrollTrigger site-header show/hide - depends on 'gsap-js' and 'scrolltrigger-js' in ppsaa_scripts()
	const initHeaderScroll = () => {
		const siteHeader = document.querySelector('.site-header');
		const siteHeaderHeight = siteHeader.offsetHeight;
		const docBody = document.body;

		// add .header-top class to <body> when nearing top of page
		ScrollTrigger.create({
			start: `+=${(-1 * siteHeaderHeight)}`,
			// end: () => `+=${document.querySelector('.site-header').offsetHeight}`,
			end: `+=${(1.34 * siteHeaderHeight)}`,
			// markers:true,
			scrub: true,
			trigger: docBody,
			toggleClass: {className: 'header-top', targets: docBody},
			onUpdate: () => {
				docBody.classList.remove('header-short')
			}
		});

		let param, checkParam, triggered;

		// show and hide fixed header based on scroll direction
		// adds buffer when scrolling up before header appears
		// inspiration for buffer: https://greensock.com/forums/topic/29468-scrolltrigger-on-fixed-header/#comment-147082
		ScrollTrigger.create({
			start: "top" + `+=${(siteHeaderHeight * .34)}`,
			end: "max",
			scrub: true,
			trigger: docBody,
			// toggleClass: {className: 'header-hide', targets: docBody},
			onUpdate: (self) => {
				// console.log( self.scroll() )
				// console.log( self.progress )
				// console.log( self.direction )
				if (self.direction === 1 && self.scroll() !== 0) {
					triggered = false
					param = self.scroll()
					docBody.classList.add('header-hide')
					docBody.classList.remove('header-short')
				}

				if (self.direction === -1 && !triggered) {
					checkParam = self.scroll()
					//console.log(param - checkParam)
					//if( param - checkParam >= 1000 || self.scroll() === 0 ) { // <--- Distance to be covered
					if (param - checkParam >= 40 || self.progress === 0) { // <--- Distance to be covered
						docBody.classList.remove('header-hide')
						docBody.classList.add('header-short')
						triggered = true
					}
				}
			}
		});
	}

	const initHeroParallax = () => {
		// let parallax_hero_figure = document.querySelector('.parallax-hero-figure')
		// let parallax_hero_parent = parallax_hero_figure.parentNode

		let parallax_hero_figure = gsap.utils.toArray('.parallax-hero-figure');
		parallax_hero_figure.forEach((item, index) => {
			let parallax_hero_parent = parallax_hero_figure.parentNode;
			// if (parallax_hero_figure) {
			gsap.to(parallax_hero_figure, {
				yPercent: 80,
				ease: 'none',
				scrollTrigger: {
					trigger: parallax_hero_parent,
					start: 'top top',
					end: 'bottom top',
					scrub: true
				},
			});
		})
	}

	document.addEventListener("DOMContentLoaded", () => {
		initHeaderScroll();
		initHeroParallax();
		initAboutParallax();
	});

	window.addEventListener("resize", () => {
	});

	// This is just an example plugin that allows us to animate a "blur" property like gsap.to(target, {blur:10})
	// and it'll feed that value to this plugin which will do all the necessary calculations to add/update a blur()
	// value in the CSS "filter" property (in browsers that support it).
	// We wrap it in an iife just so that we can declare some local variables in a private scope at the top.
	// if you'd like this as an ES Module or minified UMD file, see https://greensock.com/forums/topic/22304-animation-backdrop-filter-blur/#comment-182745
	(function() {
		const blurProperty = gsap.utils.checkPrefix("filter"),
			blurExp = /blur\((.+)?px\)/,
			getBlurMatch = target => (gsap.getProperty(target, blurProperty) || "").match(blurExp) || [];

		gsap.registerPlugin({
			name: "blur",
			get(target)
			{
				return +(getBlurMatch(target)[1]) || 0;
			},
			init(target, endValue)
			{
				let data = this,
					filter = gsap.getProperty(target, blurProperty),
					endBlur = "blur(" + endValue + "px)",
					match = getBlurMatch(target)[0],
					index;
				if (filter === "none") {
					filter = "";
				}
				if (match) {
					index = filter.indexOf(match);
					endValue = filter.substr(0, index) + endBlur + filter.substr(index + match.length);
				} else {
					endValue = filter + endBlur;
					filter += filter ? " blur(0px)" : "blur(0px)";
				}
				data.target = target;
				data.interp = gsap.utils.interpolate(filter, endValue);
			},
			render(progress, data)
			{
				data.target.style[blurProperty] = data.interp(progress);
			}
		});
	})();

	// Intro animation
	const initIntroText = () => {
		let intro_bg = document.querySelectorAll('.intro-bg');
		let man = document.querySelectorAll('.intro-man');
		let words = document.querySelectorAll('#intro-text p');
		let button = document.querySelectorAll('.hide-intro');

		// gsap.set(intro_bg, {opacity: 1, scale: 2, autoAlpha: 1});
		gsap.set(man, {opacity: 0, scale: 0.9});
		gsap.set(button, {opacity: 0});

		if (words) {

		}
		words.forEach((word) => {
			let letters = word.textContent.split('');
			word.textContent = "";
			letters.forEach((letter) => {
				let span = document.createElement('span');
				span.textContent = letter;
				span.className = 'letter';
				word.append(span);
			});
		});

		let introContentTl = gsap.timeline({
			repeat: 0,
			defaults: {stagger: 0.01},
			paused: true,
		});


		words.forEach((word, i) => {
			if (i) {
				introContentTl.from(word.childNodes, {
					blur: 4,
					autoAlpha: 0,
					ease: 'expo.out'
				}, "+=0.2");
				introContentTl.to(words[i - 1].childNodes, {
					blur: 0,
					autoAlpha: 1,
					ease: 'expo.in'
				}, '<-=0.1');
			}
		});

		introContentTl.fromTo(words[0].childNodes, {
			  blur: 4,
			  autoAlpha: 0,
		  }, {
			  blur: 0,
			  autoAlpha: 1,
			  ease: 'expo.out',
			  immediateRender: false,
		  }, '+=0.2')
		  .to(words[words.length - 1].childNodes, {
			  blur: 4,
			  autoAlpha: 0,
			  ease: 'expo.in'
		  }, '<-=0.1');


		gsap.to(button, {
			autoAlpha: 1,
			ease: 'expo.out'
		}, '-=3.25');


		let introBgTl = gsap.timeline({
			repeat: 0,
			// paused: true,
		});


		introBgTl.to(man, {
			duration: 5,
			autoAlpha: 1,
			scale: 1,
		}, '<-=0.1');


		introBgTl.fromTo(intro_bg, {
			scale: 2,
		}, {
			duration: 7,
			scale: 1,
			// immediateRender: true,
		}, '<-=0.1');

		gsap.from(words[0].childNodes, {
			blur: 4,
			autoAlpha: 0,
			// ease: 'expo.out',
			stagger: 0.01,
			// onComplete: () => introContentTl.play(),
		});
	}

	let intro_hide_btn = document.querySelector('.hide-intro');

	if (intro_hide_btn) {
		initIntroText();
		initHeaderScroll();

		const introTl = gsap.timeline({
			paused: true,
			onComplete: function() {
				docBody.classList.remove('intro-visible')
			}
		});

		const introBody = gsap
			.timeline({paused: true})
			.to('#home-intro', {
				scale: 1.3,
				duration: 1.5
			},'=')
			.to('#home-intro', {
				autoAlpha: 0,
				duration: 1
			}, '-=1.5');

		// Add timelines to Master
		introTl
			.add(introBody.tweenTo(introBody.duration()))

		intro_hide_btn.addEventListener('click', (e) => {
			introTl.play();
			e.preventDefault();
		});
	}

	//video modal
	let trailer_show_btn = document.querySelector('.show-trailer');

	if (trailer_show_btn) {
		trailer_show_btn.addEventListener('click', (e) => {
			document.documentElement.classList.add('modal-visible');
			e.preventDefault();
		});
	}

	const modal = document.querySelectorAll('.modal');
	for (let i = 0; i < modal.length; i++) {
		if (modal[i]) {

			const modal_close = modal[i].querySelector('.close-modal');
			const ytVideo = modal[i].querySelector('iframe');
			let src = ytVideo.src;

			['click', 'touchend'].forEach(function(event) {
				modal[i].addEventListener(event, onModalClose, false);
				const modal_content = modal[i].querySelector('.modal-content');
				modal_content.addEventListener(event, function(event) {
					event.stopPropagation()
				}, false);
			});

			function onModalClose()
			{
				document.documentElement.classList.remove('modal-visible');
				const videoSrc = ytVideo.src;
				ytVideo.src = videoSrc;
			}
		}
	}

// With Master

	const initAboutParallax = () => {

		let parallax_about_parent = document.querySelector('#home-about-figure');
		// let parallax_about_bg1 = document.querySelector('#about-bg-1');
		// 	gsap.from(parallax_about_bg1, {
		// 		yPercent: -5,
		// 		ease: 'none',
		// 		duration: 1,
		// 		scrollTrigger: {
		// 			trigger: parallax_about_parent,
		// 			start: 'top bottom',
		// 			end: 'top 30%',
		// 			scrub: 0.1,
		// 		},
		// 	});

		let parallax_about_bg2 = document.querySelector('#about-bg-2');
		gsap.from(parallax_about_bg2, {
			yPercent: 60,
			ease: 'power1.out',
			// ease: 'none',
			duration: 0.5,
			scrollTrigger: {
				toggleActions: 'play complete resume reverse',
				trigger: parallax_about_parent,
				start: 'top 60%',
				// end: 'top 15%',
				// scrub: 0.1,
			},
		});

		let parallax_about_bg3 = document.querySelector('#about-bg-3');
		gsap.from(parallax_about_bg3, {
			yPercent: 100,
			ease: 'power1.out',
			// ease: 'none',
			duration: 0.5,
			scrollTrigger: {
				toggleActions: 'play complete resume reverse',
				trigger: parallax_about_parent,
				start: 'top 60%',
				// end: 'top 15%',
				// scrub: 0.1,
			},
		});

		/* --- Split the text, Client Title --- */
		function setupSplits()
		{
			const targets = gsap.utils.toArray(".about-heading");
			targets.forEach((target) => {
				let SplitClient = new SplitText(target, {type: "words,chars"});
				let chars = SplitClient.chars; //an array of all the divs that wrap each character
				gsap.from(chars, {
					duration: 0.8,
					autoAlpha: 0,
					blur: 4,
					ease: "none",
					stagger: 0.02,
					scrollTrigger: {
						toggleActions: 'play complete resume reverse',
						trigger: parallax_about_parent,
						// markers: true,
						start: "top 95%",
						// end: "bottom center",
						// scrub: 0.1
					}
				});
			});
		}

		setupSplits();

	}


	/* --- Split the text, Client Title --- */
	function setupFadeText()
	{
		let targets = gsap.utils.toArray(".fadein-text");
		targets.forEach((target) => {
			let parent = target.parentNode;
			let SplitClient = new SplitText(target, {type: "words,chars"});
			let chars = SplitClient.chars; //an array of all the divs that wrap each character
			gsap.from(chars, {
				duration: 0.8,
				autoAlpha: 0,
				blur: 4,
				ease: "none",
				stagger: 0.1,
				scrollTrigger: {
					toggleActions: 'play complete resume reverse',
					trigger: parent,
					// markers: true,
					start: "top 75%",
					// end: "bottom center",
					// scrub: 0.1
				}
			});
		});
	}

	setupFadeText();

	function setupFadeWords()
	{
		let targets = gsap.utils.toArray(".fadein-words");
		targets.forEach((target) => {
			let parent = target.parentNode;
			let SplitClient = new SplitText(target, {type: "words"});
			let chars = SplitClient.words; //an array of all the divs that wrap each character
			gsap.from(chars, {
				duration: 0.8,
				autoAlpha: 0,
				blur: 4,
				ease: "none",
				stagger: 0.1,
				scrollTrigger: {
					toggleActions: 'play complete resume reverse',
					trigger: parent,
					// markers: true,
					start: "top 75%",
					// end: "bottom center",
					// scrub: 0.1
				}
			});
		});
	}

	setupFadeWords();


	function setupReveal()
	{
		let revealContainers = document.querySelectorAll('.reveal');

		revealContainers.forEach((container) => {
			let content = container.querySelectorAll(':scope > *');
			let tl = gsap.timeline({
				scrollTrigger: {
					start: 'top 95%',
					trigger: container,
					toggleActions: 'play complete resume reverse'
				}
			});

			// tl.set(container, { autoAlpha: 1 });
			tl.from(content, {
				autoAlpha: 0,
				blur: 5,
				duration: 1,
				stagger: 0.2,
				yPercent: 40,
				ease: 'power1.out',
			});
		});

	}

	setupReveal();

	function setupAboutReveal()
	{
		console.log('setupAboutReveal');
		let container = document.querySelector('.about-reveal');
		let content = container.querySelectorAll(':scope > *');
		let tl = gsap.timeline({
			scrollTrigger: {
				start: 'top 95%',
				trigger: container,
				toggleActions: 'play complete resume reverse'
			}
		});

		// tl.set(container, { autoAlpha: 1 });
		tl.from(content, {
			autoAlpha: 0,
			// blur: 5,
			duration: 1,
			stagger: 0.2,
			yPercent: 40,
			ease: 'power1.out',
		});
	}
	setupAboutReveal();


	function setupFadeIn()
	{
		let fadeInContainers = document.querySelectorAll('.fadeIn');

		fadeInContainers.forEach((container) => {
			let content = container.querySelectorAll(':scope > *');
			let tl = gsap.timeline({
				scrollTrigger: {
					start: 'top 75%',
					trigger: container,
					toggleActions: 'play complete resume reverse'
				}
			});

			tl.set(container, { autoAlpha: 1 });
			tl.from(content, {
				autoAlpha: 0,
				blur: 5,
				duration: 1.25,
				ease: 'power1.out',
			});
		});

	}

	setupFadeIn();


	function setupBlurIn()
	{
		let blurInContainers = document.querySelectorAll('.blurIn');

		blurInContainers.forEach((container) => {
			let content = container.querySelectorAll(':scope > *');
			let tl = gsap.timeline({
				scrollTrigger: {
					start: 'top 75%',
					trigger: container,
					toggleActions: 'play complete resume reverse'
				}
			});

			tl.set(container, { autoAlpha: 1 });
			tl.from(content, {
				autoAlpha: 0,
				blur: 5,
				duration: 1.25,
				ease: 'power1.out',
			});
		});

	}

	setupBlurIn();

})(window, window.document);


