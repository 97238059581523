const form = document.querySelector('form');
const messageDiv = document.querySelector('#form-message');
const successMessage = "You're on the list!";

const emailIsValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const showMessage = (msg, className) => {
  messageDiv.removeAttribute('class');
  messageDiv.classList.add(className);
  messageDiv.innerHTML = msg;
};

const handleSubmit = (event) => {
  event.preventDefault();

  const f = event.target;
  const formData = new FormData(f);
  const email = formData.get('email');

  if ( ! emailIsValid(email)) {
    showMessage('The email you entered is invalid.', 'error');
    return false;
  }

  fetch("/", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    body: new URLSearchParams(formData).toString()
  })
  .then(() => {
    f.style.display = 'none';
    showMessage(successMessage, 'success');
  })
  .catch((error) => {
      showMessage(error, 'error');
  })
};

if ( form ) {
  form.addEventListener('submit', handleSubmit);
}
